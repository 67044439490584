import Octopus from '../assets/images/Octopus.svg'
import EventLogo from '../assets/images/eventlogo.svg'
function Content(){
    return(<div className="row event-content-body mt-4">
        <div className="col-12 col-xxl-9 col-xl-8 col-lg-7 col-sm-6 d-flex flex-column justify-content-center" >
        <img src={EventLogo} alt={EventLogo} className='events-logo' /><br/>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra, Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra,Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra
            </p>
        </div>
        <div className='col-12 col-xxl-3 col-xl-4 col-lg-5 col-sm-6 px-0'>
            <img src={Octopus} alt={Octopus} />
        </div>

    </div>)
}

export default Content