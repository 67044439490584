import axios from "axios";
import React, { useEffect, useState } from "react";
import Card from "./Card";
import {Dropdown} from 'react-bootstrap'

function Event() {
    const [data,setData] = useState([])
    const [searchBy,setSeachBy] = useState('')
    const [count,setCount] = useState(0)
    const [limit,setLimit] = useState(50)
    const [isPast, setIsPast] = useState(true)
    const [err, setErr] = useState(false)
    useEffect(()=> {
        getData(0)
    },[searchBy, isPast])

    const getData = (loadLimit = 0) => {
        const previousLimit = limit
        setLimit(previousLimit+loadLimit)
        const params ={
            past_events: isPast,
            limit: limit + loadLimit,
            search_query : searchBy
        }
        axios.get('https://iitm1blt3l.execute-api.ap-southeast-1.amazonaws.com/dev/hosted-events?limit=8',{params:params})
            .then(res => {
                setData(res.data.events)
                setCount(res.data.count)   
                setErr(false)
            })
            .catch(() =>{
              setErr(true)
        })
    }
    
        return(<React.Fragment>
            <div className="d-flex justify-content-center">
                <div className="search-body d-flex">
                    <div className="d-flex flex-column p-3" >
                        <h6>Search</h6>
                        <input 
                            type='text'
                            value={searchBy}
                            onChange={(e) => setSeachBy(e.target.value)}
                         />
                    </div>
                    <div className="d-flex flex-column p-3" >
                    <h6>Past Events</h6>
                    <Dropdown >
                        <Dropdown.Toggle variant="none" id="dropdown-basic">
                          {isPast ? 'true': 'false'}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={setIsPast.bind(this,true)} href="#/">true</Dropdown.Item>
                          <Dropdown.Item onClick={setIsPast.bind(this,false)} href="#/">false</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    </div>
                </div>
            </div>
            {!err ? <React.Fragment>  
            <div className="position-relative event-count" >
                {count} Events
            </div>
            <div>
                <div className="event-cards" >
                    {data.map((data,index)=> <Card key={index} name={data.name} is_free={data.is_free} is_virtual={data.is_virtual} />)}
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-4" >
                <span className="border-span" />
                <button className="load-button mx-2" onClick={getData.bind(this,19)} disabled={!(data.length < count)} > {data.length < count ? 'Load more' : 'The end' } </button>
                <span className="border-span"/>
            </div>
            </React.Fragment>: <React.Fragment>
                <h5>No content to show</h5>
                </React.Fragment>}
        </React.Fragment>)
}
export default Event