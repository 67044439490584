import CardLogo from '../assets/images/cardimg.svg'
import mike from '../assets/images/mike.svg'
import info from '../assets/images/info.svg'
import union from '../assets/images/Union.svg'

function Card(props){
    return(<div className='card position-relative' >
        <img className='card-img-top' src={CardLogo} alt={CardLogo} />
        <img className="union-img" src={union} alt={union} />
        <div className='p-4' >
        <h5 className="card-title">{props.name}</h5>
            <div className=' font-size-13 d-flex'>
                <div className='d-flex -align-items-center' >
                    <img src={mike} alt={mike} className='mr-1' />
                    Radison Blue</div>
                <div className='d-flex -align-items-center ml-auto'>  
                    <img src={info} alt={info} className='mr-1' />
                    <span>{props.is_free ? 'Free' : 'Paid' }</span> |  
                    <span>{props.is_virtual? 'Online' : 'Offline' }</span>
                        
                 </div>
            </div>
        </div>
    </div>)
}
export default Card